import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable, map, of, switchMap } from 'rxjs';
import { AuthGatewayService } from '../services/auth-gateway.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { CommonService } from 'src/app/common/services/common.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private Router: Router,
        private StorageMap: StorageMap,
        private AuthGatewayService: AuthGatewayService,
        private CommonService: CommonService
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.AuthGatewayService.validateUser().pipe(
            switchMap((res: any) => {
                if (res.success) {
                    return this.StorageMap.get('user').pipe(
                        map((user: any) => {
                            const module_code = (
                                route.data as { module_code: string }
                            ).module_code;
    
                            if (user?.module_codes?.includes(module_code) || module_code == '') {
                                return true;
                            } else {
                                this.Router.navigate(['/dashboard']);
                                this.CommonService.createToast(
                                    'You are not authorized to access this page'
                                );
                                return false;
                            }
                        })
                    );
                } else {
                    this.StorageMap.clear().subscribe(() => {});
                    return of(this.Router.createUrlTree(['/login'], { queryParams: { redirect: state.url } }));
                }
            })
        );
    }
}
