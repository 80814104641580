import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthGatewayService {
    private endpoint = 'auth';

    constructor(private HttpClient: HttpClient) {}

    public validateUser = (): Observable<{}> => {
        const url = `${environment.api_url}/${this.endpoint}`;
        return this.HttpClient.get(url);
    };

    public login = (user: any) => {
        const url = `${environment.api_url}/${this.endpoint}`;
        return this.HttpClient.post(url, user);
    };

    public logout = () => {
        const url = `${environment.api_url}/${this.endpoint}`;
        return this.HttpClient.delete(url);
    };
}
