import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from './common/services/common.service';
import { IonicModule } from '@ionic/angular';
import { IonAccordionGroup } from '@ionic/angular/standalone';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Observable, filter } from 'rxjs';
import { AppModule } from './app.module';
import { StaffProfileComponent } from 'src/app/common/components/staff-profile/staff-profile.component';
import { addIcons } from 'ionicons';
import {
    gridOutline,
    gridSharp,
    peopleOutline,
    peopleSharp,
    layersOutline,
    layersSharp,
    personOutline,
    personSharp,
    medkitSharp,
    medkitOutline,
    briefcaseOutline,
    briefcaseSharp,
    documentOutline,
    documentSharp,
    calculatorOutline,
    calculatorSharp,
} from 'ionicons/icons';
import moment from 'moment-timezone';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        AppModule,
        CommonModule,
        NgxDatatableModule,
        StaffProfileComponent,
    ],
})
export class AppComponent {
    @ViewChild('navAccordion', { static: true })
    navAccordion!: IonAccordionGroup;

    private user$: Observable<any>;
    public user: any;
    public env = '';
    public version = '';
    public darkMode = false;

    public appPages = [
        {
            title: 'Dashboard',
            url: '/dashboard',
            icon: 'grid',
        },
        {
            title: 'Calculator',
            url: '/calculator',
            icon: 'calculator',
            module_code: 'tier_calculator_view',
        },
        {
            title: 'Staff',
            icon: 'people',
            sub_pages: [
                {
                    title: 'Masterlist',
                    url: '/staff/masterlist',
                    module_code: 'staff_get_all',
                },
                {
                    title: 'Departments',
                    url: 'staff/departments',
                    module_code: 'department_get_all',
                },
                {
                    title: 'Modules',
                    url: '/staff/modules',
                    module_code: 'module_get_all',
                },
                {
                    title: 'Timelogs',
                    url: '/staff/timelogs',
                    module_code: 'staff_timelog',
                },
                {
                    title: 'Payroll',
                    url: '/staff/payroll',
                    module_code: 'staff_payroll_get_all',
                },
                {
                    title: 'Holidays',
                    url: '/staff/holidays',
                    module_code: 'holiday_get_all',
                },
                {
                    title: 'Reports',
                    url: '/staff/reports',
                    module_code: 'staff_report',
                },
            ],
        },
        {
            title: 'Providers',
            icon: 'medkit',
            sub_pages: [
                {
                    title: 'Masterlist',
                    url: '/providers/masterlist',
                    module_code: 'provider_view_masterlist',
                },
                {
                    title: 'Locations',
                    url: '/providers/locations',
                    module_code: 'location_get_all',
                },
                {
                    title: 'Examinations',
                    url: '/providers/examinations',
                    module_code: 'examination_get_all',
                },
            ],
        },
        {
            title: 'Contractors',
            icon: 'briefcase',
            sub_pages: [
                {
                    title: 'Masterlist',
                    url: '/contractors/masterlist',
                    module_code: 'contractor_get_all',
                },
            ],
        },
        {
            title: 'DBQs',
            icon: 'document',
            sub_pages: [
                {
                    title: 'Masterlist',
                    url: '/dbq/masterlist',
                    module_code: 'dbq_get_all',
                },
                {
                    title: 'Groups',
                    url: '/dbq/groups',
                    module_code: 'dbq_group_get_all',
                },
            ],
        },
        {
            title: 'My Account',
            icon: 'person',
            sub_pages: [
                {
                    title: 'My Profile',
                    url: '/my-account/profile',
                    module_code: '',
                },
                {
                    title: 'My Timelogs',
                    url: '/my-account/timelogs',
                    module_code: '',
                },
                {
                    title: 'My Payslips',
                    url: '/my-account/payslips',
                    module_code: '',
                },
            ],
        },
    ];

    constructor(
        private Router: Router,
        private StorageMap: StorageMap,
        private CommonService: CommonService
    ) {
        moment.tz.setDefault('Asia/Manila');

        this.user$ = this.StorageMap.watch('user');
        this.user$.subscribe((user) => {
            this.user = user;
        });

        addIcons({
            gridOutline,
            gridSharp,
            peopleOutline,
            peopleSharp,
            layersOutline,
            layersSharp,
            personOutline,
            personSharp,
            medkitOutline,
            medkitSharp,
            briefcaseOutline,
            briefcaseSharp,
            documentOutline,
            documentSharp,
            calculatorOutline,
            calculatorSharp,
        });

        this.highlightActiveSubpage();

        this.env = this.CommonService.getEnvironment();
        this.version = this.CommonService.getVersion();
    }

    ngOnInit() {
        this.checkDarkMode();
    }

    public highlightActiveSubpage = () => {
        this.Router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: any) => {
                const currentUrl = event.url;

                this.appPages.forEach((page) => {
                    if (page.sub_pages) {
                        page.sub_pages.forEach((subPage) => {
                            if (currentUrl == subPage.url) {
                                this.navAccordion.value = page.title;
                                return;
                            }
                        });
                    }
                });
            });
    };

    public checkDarkMode() {
        const checkIsDarkMode = localStorage.getItem('dark_mode');
        if (checkIsDarkMode == 'true') {
            this.darkMode = true;
        } else {
            this.darkMode = false;
        }
        document.body.classList.toggle('dark', this.darkMode);
    }
}
