import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AppModule } from 'src/app/app.module';

@Component({
    selector: 'app-staff-status-logs-modal',
    templateUrl: './staff-status-logs-modal.component.html',
    standalone: true,
    imports: [AppModule, IonicModule, NgxDatatableModule],
})
export class StaffStatusLogsModalComponent implements OnInit {
    public name: string = '';
    public date: string = '';
    public statusLogs: any;
    public totalBioBreakHours: number = 0.00;
    public totalLunchBreakHours: number = 0.00;

    constructor(private ModalController: ModalController) {}

    ngOnInit() {}

    public closeStatusLogModal = () => {
        return this.ModalController.dismiss(null, 'cancel');
    };
    
}
