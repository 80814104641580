import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class StaffTimelogsGatewayService {
    private endpoint = 'staff-timelogs';

    constructor(private HttpClient: HttpClient) {}

    public getAllStaffTimelogs = (date: string) => {
        const url = `${environment.api_url}/${this.endpoint}?date=${date}`;
        return this.HttpClient.get(url);
    };

    public getAllStaffTimelogsSummary = (
        startDate: string,
        endDate: string
    ) => {
        const params = `start=${startDate}&end=${endDate}`;
        const url = `${environment.api_url}/${this.endpoint}?${params}`;
        return this.HttpClient.get(url);
    };

    public getStaffTimelogs = (
        email: string,
        startDate: string,
        endDate: string
    ) => {
        const params = `start=${startDate}&end=${endDate}`;
        const url = `${environment.api_url}/${this.endpoint}/${email}?${params}`;
        return this.HttpClient.get(url);
    };

    public getStaffTimelog = (email: string, date: string) => {
        const url = `${environment.api_url}/${this.endpoint}/${email}?date=${date}`;
        return this.HttpClient.get(url);
    };

    public createStaffTimelog = (staffTimelog: any) => {
        const url = `${environment.api_url}/${this.endpoint}`;
        return this.HttpClient.post(url, staffTimelog);
    };

    public updateStaffTimelog = (staffTimelogId: string, staffTimelog: any) => {
        const url = `${environment.api_url}/${this.endpoint}/${staffTimelogId}`;
        return this.HttpClient.put(url, staffTimelog);
    };

    public deleteStaffTimelog = (staffTimelogId: string, staffId: string) => {
        const url = `${environment.api_url}/${this.endpoint}/${staffTimelogId}/${staffId}`;
        return this.HttpClient.delete(url);
    }
}
