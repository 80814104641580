import { Injectable } from '@angular/core';
import moment from 'moment';
import { ModalController } from '@ionic/angular';
import { StaffStatusLogsModalComponent } from '../../staff/components/staff-status-logs-modal/staff-status-logs-modal.component';

@Injectable({
    providedIn: 'root',
})
export class StatusLogsService {
    constructor(private modalController: ModalController) {}

    public openStatusLogsModal = async (
        staffStatusLogs: any,
        staffName: string,
        date: string
    ) => {
        const statusLogs = staffStatusLogs;

        statusLogs.forEach(
            (log: {
                start_time: moment.MomentInput;
                end_time: moment.MomentInput;
                total_hours: string;
            }) => {
                const startTime = moment(log.start_time);
                const endTime = moment(log.end_time);
                const duration = moment.duration(endTime.diff(startTime));
                log.total_hours = duration.asHours().toFixed(2);
            }
        );

        const bioBreakLogs = statusLogs.filter(
            (log: { status: string }) => log.status === 'bio_break'
        );
        const lunchBreakLogs = statusLogs.filter(
            (log: { status: string }) => log.status === 'lunch_break'
        );
       
        const totalBioBreakHours = bioBreakLogs.reduce(
            (total: any, log: { total_hours: any }) => total + parseFloat(log.total_hours),
            0
        );
        const totalLunchBreakHours = lunchBreakLogs.reduce(
            (total: any, log: { total_hours: any }) => total + parseFloat(log.total_hours),
            0
        );

        try {
            const statusLogModal = await this.modalController.create({
                component: StaffStatusLogsModalComponent,
                componentProps: {
                    name: staffName,
                    date: date,
                    statusLogs: statusLogs,
                    totalBioBreakHours: parseFloat(totalBioBreakHours),
                    totalLunchBreakHours: parseFloat(totalLunchBreakHours),
                },
            });

            statusLogModal.present();
        } catch (error) {
            console.error('Error opening status logs modal:', error);
        }
    };
}
