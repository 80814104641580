import { Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { LoginAuthGuard } from './auth/guards/login-auth.guard';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'login',
        loadComponent: () =>
            import('./login/login.page').then((m) => m.LoginPage),
        canActivate: [LoginAuthGuard],
    },
    {
        path: 'forgot-password',
        loadComponent: () =>
            import('./forgot-password/forgot-password.page').then(
                (m) => m.ForgotPasswordPage
            ),
    },
    {
        path: 'password-request/:request_id',
        loadComponent: () =>
            import('./password-request/password-request.page').then(
                (m) => m.PasswordRequestPage
            ),
        canActivate: [LoginAuthGuard],
    },
    {
        path: 'dashboard',
        loadComponent: () =>
            import('./dashboard/dashboard.page').then((m) => m.DashboardPage),
        canActivate: [AuthGuard],
        data: { module_code: '' },
    },
    {
        path: 'calculator',
        loadComponent: () => 
            import(
                './calculator/pages/calculator-view/calculator-view.page'
            ).then( m => m.CalculatorViewPage),
        canActivate: [AuthGuard],
        data: { module_code: 'tier_calculator_view' },
    },
    {
        path: 'staff',
        children: [
            {
                path: 'masterlist',
                loadComponent: () =>
                    import(
                        './staff/pages/staff-masterlist/staff-masterlist.page'
                    ).then((m) => m.StaffMasterlistPage),
                canActivate: [AuthGuard],
                data: { module_code: 'staff_get_all' },
            },
            {
                path: 'masterlist/view/:email',
                loadComponent: () =>
                import(
                    './staff/pages/staff-view/staff-view.page'
                ).then((m) => m.StaffViewPage),
                canActivate: [AuthGuard],
                data: { module_code: 'staff_get' },
            },
            {
                path: 'masterlist/add',
                loadComponent: () =>
                    import(
                        './staff/pages/staff-masterlist-add/staff-masterlist-add.page'
                    ).then((m) => m.StaffMasterlistAddPage),
                canActivate: [AuthGuard],
                data: { module_code: 'staff_add' },
            },
            {
                path: 'masterlist/edit/:email',
                loadComponent: () =>
                    import(
                        './staff/pages/staff-masterlist-edit/staff-masterlist-edit.page'
                    ).then((m) => m.StaffMasterlistEditPage),
                canActivate: [AuthGuard],
                data: { module_code: 'staff_edit' },
            },
            {
                path: 'departments',
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import(
                                './departments/pages/departments/departments.page'
                            ).then((m) => m.DepartmentsPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'department_get_all' },
                    },
                    {
                        path: 'add',
                        loadComponent: () =>
                            import(
                                './departments/pages/departments-add/departments-add.page'
                            ).then((m) => m.DepartmentsAddPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'department_add' },
                    },
                    {
                        path: 'edit/:department_code',
                        loadComponent: () =>
                            import(
                                './departments/pages/departments-edit/departments-edit.page'
                            ).then((m) => m.DepartmentsEditPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'department_edit' },
                    },
                    {
                        path: ':department_code/positions',
                        loadComponent: () =>
                            import(
                                './departments/pages/positions/positions.page'
                            ).then((m) => m.PositionsPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'position_get_all' },
                    },
                    {
                        path: ':department_code/positions/add',
                        loadComponent: () =>
                            import(
                                './departments/pages/positions-add/positions-add.page'
                            ).then((m) => m.PositionsAddPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'position_add' },
                    },
                    {
                        path: ':department_code/positions/edit/:access_code',
                        loadComponent: () =>
                            import(
                                './departments/pages/positions-edit/positions-edit.page'
                            ).then((m) => m.PositionsEditPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'position_edit' },
                    },
                ],
            },
            {
                path: 'modules',
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import(
                                './modules/pages/modules-masterlist/modules-masterlist.page'
                            ).then( m => m.ModulesMasterlistPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'module_get_all' },
                    },
                    {
                        path: 'add',
                        loadComponent: () =>
                            import(
                                './modules/pages/module-form/module-form.page'
                            ).then((m) => m.ModuleFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'module_add' },
                    },
                    {
                        path: 'edit/:id',
                        loadComponent: () =>
                            import(
                                './modules/pages/module-form/module-form.page'
                            ).then((m) => m.ModuleFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'module_edit' },
                    },
                ],
            },
            {
                path: 'timelogs',
                loadComponent: () =>
                    import(
                        './staff/pages/staff-timelogs/staff-timelogs.page'
                    ).then((m) => m.StaffTimelogsPage),
                canActivate: [AuthGuard],
                data: { module_code: 'staff_timelog' },
            },
            {
                path: 'timelogs/add',
                loadComponent: () =>
                    import(
                        './staff/pages/staff-timelogs-add/staff-timelogs-add.page'
                    ).then((m) => m.StaffTimelogsAddPage),
                canActivate: [AuthGuard],
                data: { module_code: 'staff_timelog_add' },
            },
            {
                path: 'timelogs/add/:email',
                loadComponent: () =>
                    import(
                        './staff/pages/staff-timelogs-add/staff-timelogs-add.page'
                    ).then((m) => m.StaffTimelogsAddPage),
                canActivate: [AuthGuard],
                data: { module_code: 'staff_timelog_edit' },
            },
            {
                path: 'timelogs/:email',
                loadComponent: () =>
                    import(
                        './staff/pages/staff-timelogs/staff-individual-timelogs.page'
                    ).then((m) => m.StaffIndividualTimelogsPage),
                canActivate: [AuthGuard],
                data: { module_code: 'staff_timelog_get' },
            },
            {
                path: 'payroll',
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import(
                                './staff/pages/staff-payroll/staff-payroll.page'
                            ).then((m) => m.StaffPayrollPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'staff_payroll_get_all' },
                    },
                    {
                        path: 'add',
                        loadComponent: () =>
                            import(
                                './staff/pages/staff-payroll-form/staff-payroll-form.page'
                            ).then((m) => m.StaffPayrollFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'staff_payroll_add' },
                    },
                    {
                        path: ':id',
                        loadComponent: () =>
                            import(
                                './staff/pages/staff-payslips-list/staff-payslips-list.page'
                            ).then((m) => m.StaffPayslipsListPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'staff_payroll_get' },
                    },
                    {
                        path: 'edit/:id',
                        loadComponent: () =>
                            import(
                                './staff/pages/staff-payroll-form/staff-payroll-form.page'
                            ).then((m) => m.StaffPayrollFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'staff_payroll_edit' },
                    },
                    {
                        path: 'payslip/:id',
                        loadComponent: () =>
                            import(
                                './staff/pages/staff-payslip/staff-payslip.page'
                            ).then((m) => m.StaffPayslipPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'staff_payslip_get' },
                    },
                    {
                        path: 'payslip/:payslip_id/adjustments/add',
                        loadComponent: () =>
                            import(
                                './staff/pages/staff-payslip-adjustment-form/staff-payslip-adjustment-form.page'
                            ).then((m) => m.StaffPayslipAdjustmentFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'staff_payslip_add_adjustment' },
                    },
                    {
                        path: 'payslip/:payslip_id/adjustments/edit/:adjustment_id',
                        loadComponent: () =>
                            import(
                                './staff/pages/staff-payslip-adjustment-form/staff-payslip-adjustment-form.page'
                            ).then((m) => m.StaffPayslipAdjustmentFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'staff_payslip_edit_adjustment' },
                    },
                ],
            },
            {
                path: 'holidays',
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import(
                                './holidays/pages/holidays/holidays.page'
                            ).then((m) => m.HolidaysPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'holiday_get_all' },
                    },
                    {
                        path: 'add',
                        loadComponent: () =>
                            import(
                                './holidays/pages/holiday-form/holiday-form.page'
                            ).then((m) => m.HolidayFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'holiday_add' },
                    },
                    {
                        path: 'edit/:id',
                        loadComponent: () =>
                            import(
                                './holidays/pages/holiday-form/holiday-form.page'
                            ).then((m) => m.HolidayFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'holiday_edit' },
                    },
                ],
            },
            {
                path: 'reports',
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import(
                                './staff/pages/staff-reports/staff-reports.page'
                            ).then((m) => m.StaffReportsPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'staff_report' },
                    },
                ],
            },
        ],
    },
    {
        path: 'providers',
        children: [
            {
                path: 'masterlist',
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import('./provider/pages/providers-masterlist/providers-masterlist.page').then( m => m.ProvidersMasterlistPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'provider_view_masterlist' },
                    },
                    {
                        path: 'add',
                        loadComponent: () =>
                            import('./provider/pages/provider-form/provider-form.page').then( m => m.ProviderFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'provider_add' },
                    },
                    {
                        path: 'edit/:id',
                        loadComponent: () =>
                            import('./provider/pages/provider-form/provider-form.page').then( m => m.ProviderFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'provider_edit' },
                    },
                ],
            },
            {
                path: 'locations',
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import('./provider/pages/provider-locations/provider-locations.page').then( m => m.ProviderLocationsPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'location_get_all' },
                    },
                    {
                        path: 'states',
                        children: [
                            {
                                path: 'add',
                                loadComponent: () =>
                                    import('./provider/pages/provider-states-form/provider-states-form.page').then( m => m.ProviderStatesFormPage),
                                canActivate: [AuthGuard],
                                data: { module_code: 'location_add' },
                            },
                            {
                                path: 'edit/:id',
                                loadComponent: () =>
                                    import('./provider/pages/provider-states-form/provider-states-form.page').then( m => m.ProviderStatesFormPage),
                                canActivate: [AuthGuard],
                                data: { module_code: 'location_edit' },
                            },
                        ],
                    },
                    {
                        path: 'cities',
                        children: [
                            {
                                path: 'add',
                                loadComponent: () =>
                                    import('./provider/pages/provider-cities-form/provider-cities-form.page').then( m => m.ProviderCitiesFormPage),
                                canActivate: [AuthGuard],
                                data: { module_code: 'location_add' },
                            },
                            {
                                path: 'edit/:id',
                                loadComponent: () =>
                                    import('./provider/pages/provider-cities-form/provider-cities-form.page').then( m => m.ProviderCitiesFormPage),
                                canActivate: [AuthGuard],
                                data: { module_code: 'location_edit' },
                            },
                        ],
                    },
                    {
                        path: 'regions',
                        children: [
                            {
                                path: 'add',
                                loadComponent: () =>
                                    import('./provider/pages/provider-regions-form/provider-regions-form.page').then( m => m.ProviderRegionsFormPage),
                                canActivate: [AuthGuard],
                                data: { module_code: 'location_add' },
                            },
                            {
                                path: 'edit/:id',
                                loadComponent: () =>
                                    import('./provider/pages/provider-regions-form/provider-regions-form.page').then( m => m.ProviderRegionsFormPage),
                                canActivate: [AuthGuard],
                                data: { module_code: 'location_edit' },
                            },
                        ],
                    },
                    {
                        path: 'clinics',
                        children: [
                            {
                                path: 'add',
                                loadComponent: () =>
                                    import('./provider/pages/provider-clinics-form/provider-clinics-form.page').then( m => m.ProviderClinicsFormPage),
                                canActivate: [AuthGuard],
                                data: { module_code: 'location_add' },
                            },
                            {
                                path: 'edit/:id',
                                loadComponent: () =>
                                    import('./provider/pages/provider-clinics-form/provider-clinics-form.page').then( m => m.ProviderClinicsFormPage),
                                canActivate: [AuthGuard],
                                data: { module_code: 'location_edit' },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'examinations',
                children: [
                    {
                        path: '',
                        loadComponent: () => import('./provider/pages/provider-examinations/provider-examinations.page').then( m => m.ProviderExaminationsPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'examination_get_all' },
                    },
                    {
                        path: 'add',
                        loadComponent: () => import('./provider/pages/provider-examination-form/provider-examination-form.page').then( m => m.ProviderExaminationFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'examination_add' },
                    },
                    {
                        path: 'edit/:id',
                        loadComponent: () => import('./provider/pages/provider-examination-form/provider-examination-form.page').then( m => m.ProviderExaminationFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'examination_edit' },
                    },
                    {
                        path: ':provider_id',
                        loadComponent: () => import('./provider/pages/provider-individual-examinations/provider-individual-examinations.page').then( m => m.ProviderIndividualExaminationsPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'examination_get' },
                    },
                ],
            },
        ],
    },
    {
        path: 'contractors',
        children: [
            {
                path: 'masterlist',
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import(
                                './contractors/pages/contractors-masterlist/contractors-masterlist.page'
                            ).then((m) => m.ContractorsMasterlistPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'contractor_get_all' },
                    },
                    {
                        path: 'add',
                        loadComponent: () =>
                            import(
                                './contractors/pages/contractor-form/contractor-form.page'
                            ).then((m) => m.ContractorFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'contractor_add' },
                    },
                    {
                        path: 'edit/:id',
                        loadComponent: () =>
                            import(
                                './contractors/pages/contractor-form/contractor-form.page'
                            ).then((m) => m.ContractorFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'contractor_edit' },
                    },
                    {
                        path: ':contractor_code',
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('./contractors/pages/contractor-info/contractor-info.page').then( m => m.ContractorInfoPage),
                                canActivate: [AuthGuard],
                                data: {
                                    module_code: 'contractor_get',
                                },
                            },
                            {
                                path: 'tiers',
                                children: [
                                    {
                                        path: 'add',
                                        loadComponent: () => import('./contractors/pages/tiers-form/tiers-form.page').then( m => m.TiersFormPage),
                                        canActivate: [AuthGuard],
                                        data: {
                                            module_code: 'contractor_tier_add',
                                        },
                                    },
                                    {
                                        path: 'edit/:id',
                                        loadComponent: () => import('./contractors/pages/tiers-form/tiers-form.page').then( m => m.TiersFormPage),
                                        canActivate: [AuthGuard],
                                        data: {
                                            module_code: 'contractor_tier_edit',
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'rates',
                                children: [
                                    {
                                        path: 'add',
                                        loadComponent: () => import('./contractors/pages/rates-form/rates-form.page').then( m => m.RatesFormPage),
                                        canActivate: [AuthGuard],
                                        data: {
                                            module_code: 'contractor_rate_add',
                                        },
                                    },
                                    {
                                        path: 'edit/:id',
                                        loadComponent: () => import('./contractors/pages/rates-form/rates-form.page').then( m => m.RatesFormPage),
                                        canActivate: [AuthGuard],
                                        data: {
                                            module_code: 'contractor_rate_edit',
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'point-of-contacts',
                                children: [
                                    {
                                        path: 'add',
                                        loadComponent: () =>
                                            import(
                                                './contractors/pages/point-of-contact-form/point-of-contact-form.page'
                                            ).then(
                                                (m) => m.PointOfContactFormPage
                                            ),
                                        canActivate: [AuthGuard],
                                        data: {
                                            module_code: 'point_of_contact_add',
                                        },
                                    },
                                    {
                                        path: 'edit/:id',
                                        loadComponent: () =>
                                            import(
                                                './contractors/pages/point-of-contact-form/point-of-contact-form.page'
                                            ).then(
                                                (m) => m.PointOfContactFormPage
                                            ),
                                        canActivate: [AuthGuard],
                                        data: {
                                            module_code:
                                                'point_of_contact_edit',
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: 'dbq',
        children: [
            {
                path: 'masterlist',
                children: [
                    {
                        path: '',
                        loadComponent: () => import('./dbq/pages/dbq-list/dbq-list.page').then( m => m.DbqListPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'dbq_get_all' },
                    },
                    {
                        path: 'add',
                        loadComponent: () => import('./dbq/pages/dbq-form/dbq-form.page').then( m => m.DbqFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'dbq_add' },
                    },
                    {
                        path: 'edit/:id',
                        loadComponent: () => import('./dbq/pages/dbq-form/dbq-form.page').then( m => m.DbqFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'dbq_edit' },
                    },
                ],
            },
            {
                path: 'groups',
                children: [
                    {
                        path: '',
                        loadComponent: () => import('./dbq/pages/dbq-group-list/dbq-group-list.page').then( m => m.DbqGroupListPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'dbq_group_get_all' },
                    },
                    {
                        path: 'add',
                        loadComponent: () => import('./dbq/pages/dbq-group-form/dbq-group-form.page').then( m => m.DbqGroupFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'dbq_group_add' },
                    },
                    {
                        path: 'edit/:id',
                        loadComponent: () => import('./dbq/pages/dbq-group-form/dbq-group-form.page').then( m => m.DbqGroupFormPage),
                        canActivate: [AuthGuard],
                        data: { module_code: 'dbq_group_edit' },
                    },
                ],
            },
        ],
    },
    {
        path: 'my-account',
        children: [
            {
                path: 'profile',
                loadComponent: () =>
                    import(
                        './my-account/pages/my-account-profile/my-account-profile.page'
                    ).then((m) => m.MyAccountProfilePage),
                canActivate: [AuthGuard],
                data: { module_code: '' },
            },
            {
                path: 'timelogs',
                loadComponent: () =>
                    import(
                        './my-account/pages/my-account-timelogs/my-account-timelogs.page'
                    ).then((m) => m.MyAccountTimelogsPage),
                canActivate: [AuthGuard],
                data: { module_code: '' },
            },
            {
                path: 'payslips',
                loadComponent: () =>
                    import(
                        './my-account/pages/my-account-payslips/my-account-payslips.page'
                    ).then((m) => m.MyAccountPayslipsPage),
                canActivate: [AuthGuard],
                data: { module_code: '' },
            },
            {
                path: 'payslips/:id',
                loadComponent: () =>
                    import(
                        './my-account/pages/my-account-payslip/my-account-payslip.page'
                    ).then((m) => m.MyAccountPayslipPage),
                canActivate: [AuthGuard],
                data: { module_code: '' },
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
];
