<div class="staff-profile {{ user?.status }}">
    <ion-grid>
        <ion-row>
            <ion-col class="vertical-center">
                <img
                    class="staff-avatar"
                    src="../../../assets/avatar.svg"
                    alt="Circular Image"
                    id="circular-image"
                />
            </ion-col>
            <ion-col size="8">
                <ion-grid class="time-tracker-container">
                    <ion-row>
                        <ion-col class="ion-no-padding">
                            <div lines="none" class="ion-text-center staff-name">
                                <ion-label>
                                    {{ user?.name }}
                                </ion-label>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="ion-no-padding">
                            <div class="ion-text-center">
                                <div
                                    *ngIf="!user?.timelog_start; else timerControls"
                                    class="start-timer-button"
                                >
                                    <ion-button
                                        (click)="onStartTimer()"
                                        color="primary"
                                        shape="round"
                                        size="small"
                                        [disabled]="processing"
                                    >
                                        <ion-icon size="small" slot="start" name="play">
                                        </ion-icon>
                                        Start Shift
                                    </ion-button>
                                </div>
                                <ng-template #timerControls>
                                    <div class="timer-controls-container">
                                        <div *ngIf="user?.timelog_start" class="timer">
                                            {{ timer | date : "HH:mm:ss" : "UTC" }}
                                        </div>
                                        <ion-button
                                            (click)="openStatusLogsModal()"
                                            class="circle-button"
                                            color="primary"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                name="information-circle-outline"
                                            ></ion-icon>
                                        </ion-button>
                                        <ion-button
                                            (click)="promptStopTime()"
                                            class="stop-timer-button"
                                            color="danger"
                                            [disabled]="processing"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                name="stop"
                                            ></ion-icon>
                                        </ion-button> 
                                    </div>
                                </ng-template>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="vertical-center ion-no-padding">
                            <ion-select
                                *ngIf="user?.status"
                                placeholder="Select Status"
                                toggleIcon="caret-down-sharp"
                                class="staff-status always-flip"
                                [(ngModel)]="selectedStatus"
                                (ionChange)="onStaffStatusChange($event)"
                                interface="popover"
                                [interfaceOptions]="{
                                    size: 'auto',
                                    side: 'bottom',
                                    alignment: 'center',
                                    cssClass: 'staff-status-popover'
                                }"
                                [disabled]="processing"                            
                            >
                                <ion-select-option value="online"
                                    >Online</ion-select-option
                                >
                                <ion-select-option value="in_a_meeting"
                                    >In a Meeting</ion-select-option
                                >
                                <ion-select-option value="busy">Busy</ion-select-option>
                                <ion-select-option value="lunch_break"
                                    >Lunch Break</ion-select-option
                                >
                                <ion-select-option value="bio_break"
                                    >Bio Break</ion-select-option
                                >
                                <ion-select-option value="official_business"
                                    >Official Business</ion-select-option
                                >
                            </ion-select>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>
