import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthGatewayService } from '../services/auth-gateway.service';

@Injectable({
    providedIn: 'root',
})
export class LoginAuthGuard implements CanActivate {
    constructor(
        private Router: Router,
        private AuthGatewayService: AuthGatewayService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.AuthGatewayService.validateUser().pipe(
            map((res: any) => {
                if (!res.success) {
                    return true;
                } else {
                    this.Router.navigate(['/']);
                    return false;
                }
            })
        );
    }
}
