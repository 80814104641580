<ion-app>
    <div class="desktop-only-container">
        <ion-text class="ion-text-center">
            <p>This application is accessible in <strong class="text-secondary">DESKTOP</strong> mode only.</p>
            <p>For best experience, please open in your desktop browser, preferably at a resolution of 1920x1080.</p>
        </ion-text>
    </div>
    <ion-split-pane contentId="main-content" when="md">
        <ion-menu
            contentId="main-content"
            type="push"
            [style.display]="user ? 'block' : 'none'"
        >
            <ion-content>
                <div class="app-header-container">
                    <div class="app-logo-container">
                        <img
                            src="/assets/logo.png"
                            alt="Veteran Medical Group Inc."
                            class="app-logo"
                        />
                        <br />
                        <ion-badge
                            class="env-badge {{ env }}"
                            *ngIf="env !== 'prod'"
                        >
                            {{ env }}
                        </ion-badge>
                    </div>
                    <div class="app-title-container">
                        <span class="app-title">
                            VMG ADMIN PORTAL v.{{ version }}
                        </span>
                    </div>
                </div>
                <app-staff-profile></app-staff-profile>
                <ion-list id="inbox-list">
                    <ion-accordion-group #navAccordion>
                        <ion-menu-toggle
                            auto-hide="false"
                            *ngFor="let page of appPages"
                        >
                            <ion-accordion
                                [value]="page.title"
                                *ngIf="page.sub_pages; else navLink"
                            >
                                <ion-item slot="header" lines="none">
                                    <ion-icon
                                        slot="start"
                                        [ios]="page.icon + '-outline'"
                                        [md]="page.icon + '-sharp'"
                                    ></ion-icon>
                                    <ion-label class="menu-item">
                                        {{ page.title }}
                                    </ion-label>
                                </ion-item>
                                <div slot="content">
                                    <ng-container
                                        *ngFor="let subPage of page.sub_pages"
                                    >
                                        <ion-item
                                            button="true"
                                            [routerLink]="[subPage.url]"
                                            routerLinkActive="selected"
                                            lines="none"
                                            *ngIf="user && user.module_codes?.includes(subPage.module_code) || subPage.module_code == ''"
                                        >
                                            <ion-label class="submenu-item">
                                                {{ subPage.title }}
                                            </ion-label>
                                        </ion-item>
                                    </ng-container>
                                </div>
                            </ion-accordion>
                            <ng-template #navLink>
                                <ion-item
                                    button="true"
                                    [routerLink]="[page.url]"
                                    routerLinkActive="selected"
                                    lines="none"
                                    *ngIf="user && user.module_codes?.includes(page.module_code) || !page.module_code"
                                >
                                    <ion-icon
                                        slot="start"
                                        [ios]="page.icon + '-outline'"
                                        [md]="page.icon + '-sharp'"
                                    ></ion-icon>
                                    <ion-label class="menu-item">
                                        {{ page.title }}
                                    </ion-label>
                                </ion-item>
                            </ng-template>
                        </ion-menu-toggle>
                    </ion-accordion-group>
                </ion-list>
            </ion-content>
        </ion-menu>
        <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
</ion-app>
